import Web3 from "web3";
import * as Constants from "./Constants";

export const makeValidatorsDeposit = async (fundingAccount, depositData, validatorsStartIndex, validatorsEndIndex) => {
  const web3 = new Web3(window.ethereum);

  // Check validatorsEndIndex
  if (validatorsEndIndex <= 0 || validatorsEndIndex > depositData.length -1)
    validatorsEndIndex = depositData.length -1

  const depositValue = `0x${(
    process.env.REACT_APP_Deposit_Per_Validator * 1e18
  ).toString(16)}`;

  const abi = JSON.parse(process.env.REACT_APP_Deposit_Contract_Api);
  const address = process.env.REACT_APP_Deposit_Contract_Address;

  window.contract = new web3.eth.Contract(abi, address);

  for(var i=validatorsStartIndex; i <= validatorsEndIndex; i++) {
    const { pubkey, withdrawal_credentials, signature, deposit_data_root } =
      depositData[i]; // get the relevant data from the raw depositData

    const tx = await window.contract.methods.deposit(
      `0x${pubkey}`,
      `0x${withdrawal_credentials}`,
      `0x${signature}`,
      `0x${deposit_data_root}`
    );

    // calculating needed gas
    let gasEstimation = await tx.estimateGas({
      from: fundingAccount,
      value: depositValue,
    });
    let gasValue = `0x${Math.ceil(
      gasEstimation * Number(process.env.REACT_APP_Transactions_Speedup_Factor)
    ).toString(16)}`;

    // sending the transaction
    tx.send({
      from: fundingAccount,
      gasPrice: await web3.eth.getGasPrice(),
      value: depositValue,
    })
      .then(function (receipt) {
        console.info(receipt);
      })
      .catch(function (error) {
        console.error(error);
      });
  }
};

export const checkIsCorrectNetwork = (_chainId) => {
  return (
    parseInt(_chainId.replace("0x", ""), 16) ===
    parseInt(process.env.REACT_APP_Chain_Id)
  );
};

export const checkIsEnoughBalance = (_balance, depositAmount) => {
  return parseInt(_balance.replace("0x", ""), 16) / 1e18 > depositAmount;
};

export const mintNFT = async (operationDetails) => {
  const web3 = new Web3(window.ethereum);
  const NftContract = new web3.eth.Contract(
    Constants.NftAbi,
    operationDetails.contractAddress
  );
  let nonce = await web3.eth.getTransactionCount(
    operationDetails.fundingAddress
  );
  let nonceFlag = false;
  let response = {};

  do {
    try {
      nonceFlag = false;

      const tx = NftContract.methods.mintNFT(
        operationDetails.receiverAddress,
        operationDetails.metadataUrl
      );

      // calculating needed gas
      let gasEstimation = await tx.estimateGas();
      let gasValue = `0x${Math.ceil(
        gasEstimation *
          Number(process.env.REACT_APP_Transactions_Speedup_Factor)
      ).toString(16)}`;

      response.success = await tx.send({
        from: operationDetails.fundingAddress,
        gas: gasValue,
        nonce: `0x${nonce.toString(16)}`,
      });
    } catch (error) {
      nonceFlag = false;
      response.error = error;

      if (isNonceError(error)) {
        nonceFlag = true;
        nonce += 1;
      }
    }
  } while (nonceFlag);
  return response;
};

const isNonceError = (error) => {
  return (
    error.toString().includes("replacement transaction underpriced") ||
    error.toString().includes("already known") ||
    error.toString().includes("nonce too low")
  );
};

import * as React from "react";
import NFTMint from "./NFTMint";

export default function NFTMain() {
  return (
    <div className="d-flex flex-column">
      <h1 className="text-center mb-5">
        {process.env.REACT_APP_Blockchain_Network_Name} NTF minting services
      </h1>
      <NFTMint />
    </div>
  );
}

import * as React from "react";
import FaucetComponent from "./FaucetComponent";

export default function FaucetMain() {
  return (
    <div className="d-flex flex-column">
      <h1 className="text-center mb-5">
        {process.env.REACT_APP_Blockchain_Network_Name} Faucet services
      </h1>
      <FaucetComponent />
    </div>
  );
}

import * as React from "react";
import * as Helpers from "../../Helpers/Helpers";

export default function StakingStepProvideAccount(props) {
  const [accounts, setAccounts] = React.useState([]);
  const [isCorrectNetwork, setIsCorrectNetwork] = React.useState(false);
  const [isEnoughBalance, setIsEnoughBalance] = React.useState(false);

  React.useEffect(() => {
    props.setNextDisabled(true);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    props.setNextDisabled(!(isCorrectNetwork && isEnoughBalance));
    // eslint-disable-next-line
  }, [isCorrectNetwork, isEnoughBalance]);

  React.useEffect(() => {
    if (window.ethereum?.isMetaMask) {
      // Check network id
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((_chainId) => {
          setIsCorrectNetwork(Helpers.checkIsCorrectNetwork(_chainId));
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (window.ethereum?.isMetaMask) {
      // Check account eligibility
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((_accounts) => {
          setAccounts(_accounts);
          props.setPublicKey(_accounts[0]);
          // Check selected account balance
          window.ethereum
            .request({
              method: "eth_getBalance",
              params: [_accounts[0], "latest"],
            })
            .then((_balance) => {
              setIsEnoughBalance(
                Helpers.checkIsEnoughBalance(_balance, props.depositAmount)
              );
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line
  }, [isCorrectNetwork]);

  React.useEffect(() => {
    if (window.ethereum?.isMetaMask) {
      // Tracking Chain id change
      window.ethereum.on("chainChanged", (_chainId) => {
        setIsCorrectNetwork(Helpers.checkIsCorrectNetwork(_chainId));
      });

      // Tracking Account Changing
      window.ethereum.on("accountsChanged", async () => {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((_accounts) => {
            setAccounts(_accounts);
            // Check selected account balance
            window.ethereum
              .request({
                method: "eth_getBalance",
                params: [_accounts[0], "latest"],
              })
              .then((_balance) => {
                setIsEnoughBalance(
                  Helpers.checkIsEnoughBalance(_balance, props.depositAmount)
                );
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="h3 mb-4">Selecting network and account</div>
        <div className="ms-4 mb-5">
          <div className="mb-5">
            <div className="h5 mb-4">
              Please select the correct network and the desired account from
              metamask extension
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex">
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  Selected network :
                </div>
                {isCorrectNetwork ? (
                  <div className="h5 ms-3 w-25 d-flex align-items-center text-success">
                    You are on {process.env.REACT_APP_Blockchain_Network_Name}{" "}
                    network
                  </div>
                ) : (
                  <div className="h5 ms-3 d-flex align-items-center text-danger">
                    You are on different network, Please switch to{" "}
                    {process.env.REACT_APP_Blockchain_Network_Name} network to
                    proceed
                  </div>
                )}
              </div>
              <div className="d-flex">
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  Selected account :
                </div>
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  {accounts[0]}
                </div>
              </div>
              <div className="d-flex">
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  Deposit Validators Number :
                </div>
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  {props.validatorsNumber} Validators
                </div>
              </div>
              <div className="d-flex">
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                   Validators Start Index :
                </div>
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  {props.validatorsStartIndex}
                </div>
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                   to Index :
                </div>
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  {props.validatorsEndIndex}
                </div>
              </div>
              <div className="d-flex">
                <div className="h5 ms-3 w-25 d-flex align-items-center">
                  Account balance :
                </div>
                {isEnoughBalance ? (
                  <div className="h5 ms-3 d-flex align-items-center text-success">
                    Current account have enough balance to make the deposit
                    (more than {props.depositAmount}{" "}
                    {process.env.REACT_APP_Blockchain_Coin_Name})
                  </div>
                ) : (
                  <div className="h5 ms-3 d-flex align-items-center text-danger">
                    Current account doesn't have enough balance to make the
                    deposit (more than {props.depositAmount}{" "}
                    {process.env.REACT_APP_Blockchain_Coin_Name})
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function StakingStepConfirmDeposit(props) {
  React.useEffect(() => {
    props.setNextDisabled(true);
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = () => {
    props.setNextDisabled((current) => !current);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="h3 mb-4">Confirm The Deposit</div>
        <div className="ms-4">
          <div className="mb-5">
            <div className="h5 mb-4">
              Please read the deposit details carefully before confirming the
              operation
            </div>
            <div className="d-flex mb-2">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Account Address :
              </div>
              <div>{props.publicKey}</div>
            </div>
            <div className="d-flex mb-2">
              <div className="h5 ms-3 w-25 d-flex align-items-center">
                Deposit Amount :
              </div>
              <div>
                {props.depositAmount}{" "}
                {process.env.REACT_APP_Blockchain_Coin_Name}
              </div>
            </div>
          </div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckboxChange} />}
              label="I have read the deposit details carefully and want to proceed"
              className="mb-3"
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
}

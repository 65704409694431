import * as React from "react";
import { Link } from "react-router-dom";

import * as Constants from "../Helpers/Constants";

export default function Home() {
  return (
    <div className="d-flex flex-column align-items-center">
      <h1 className="text-center mb-5">
        Welcome to {process.env.REACT_APP_Blockchain_Network_Name} services
      </h1>
      <div className="d-flex justify-content-evenly w-75 mt-5">
        <Link className="btn btn-primary" to={Constants.Paths.NFT}>
          NFT Services
        </Link>
        <Link className="btn btn-success" to={Constants.Paths.Faucet}>
          Faucet Services
        </Link>
        <Link className="btn btn-warning" to={Constants.Paths.Staking}>
          Staking Services
        </Link>
      </div>
    </div>
  );
}

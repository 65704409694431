import * as React from "react";
import ReactLoading from "react-loading";

export default function Loading(props) {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="h5 mb-3 mt-5">{props.message}</div>
      <ReactLoading
        type={props.type}
        color={props.color}
        height={props.height}
        width={props.height}
      />
    </div>
  );
}

import * as React from "react";

export default function StakingStepGenerateKeys(props) {
  React.useEffect(() => {
    props.setNextDisabled(false);
  }, [props]);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="h3 mb-4">Generate your keys</div>
        <div className="ms-4 mb-5">
          <div className="mb-5">
            <div className="h5 mb-4">
              1 - Go to the Staking Deposit Cli Repository using button below
            </div>
            <a
              href="https://github.com/oone-world/staking-deposit-cli"
              className="btn btn-success ms-3"
              target="_blank"
              rel="noreferrer"
            >
              Staking Deposit Cli
            </a>
          </div>
          <div className="h5">
            2 - Follow the instructions steps to create your deposit data
          </div>
        </div>
      </div>
    </>
  );
}

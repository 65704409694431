import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Outlet from "../Components/Outlet";
import NFTMain from "../Components/NFT/NFTMain";
import Home from "../Components/Home";
import FaucetMain from "../Components/Faucet/FaucetMain";

import * as Constants from "../Helpers/Constants";
import StakingMain from "../Components/Staking/StakingMain";

export default function Routing() {
  return (
    <>
      <div style={{ paddingTop: "5vh", minHeight: "100vh" }}>
        <Routes>
          <Route path="" element={<Outlet />}>
            <Route index element={<Navigate to={Constants.Paths.Home} />} />
            <Route path={Constants.Paths.Home} element={<Home />} />
            <Route path={Constants.Paths.NFT} element={<NFTMain />} />
            <Route path={Constants.Paths.Faucet} element={<FaucetMain />} />
            <Route path={Constants.Paths.Staking} element={<StakingMain />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

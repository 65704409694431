import * as React from "react";

export default function StakingStepNextSteps(props) {
  React.useEffect(() => {
    props.setNextDisabled(true);
  }, [props]);

  return (
    <>
      <div className="d-flex flex-column">
        <div className="h3 mb-4">Next steps</div>
        <div className="ms-4 mb-5">
          <div className="mb-5">
            <div className="h5 mb-3">
              After confirming your transactions, you can start your validation
              client and become a validator for our blockchain.
            </div>
            <div className="h5 mb-3">Thank you for using our services.</div>
          </div>
        </div>
      </div>
    </>
  );
}

import * as React from "react";

import * as Requests from "../../Helpers/Requests";
import Alert from "../Alert";
import Loading from "../Loading";
import BackToHome from "../BackToHome";

export default function FaucetComponent() {
  // Alert component state
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [receiver, setReceiver] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [tweetUrl, setTweetUrl] = React.useState("");

  // const handleReceiverChange = (e) => setReceiver(e.target.value);
  // const handleAmountChange = (e) => setAmount(e.target.value);
  // const handleTweetUrlChange = (e) => setTweetUrl(e.target.value);

  const handleLimit = (e) => {
    let maxLength = 5;
    if (e.target.value.length > maxLength)
      e.target.value = e.target.value.slice(0, maxLength);
  };

  const showAlert = (severtity, message) => {
    setAlertOpen(true);
    setSeverity(severity);
    setMessage(message);
  }

  const validateTweetUrl = (url) => {
    const validTweetUrl = new RegExp('^https://twitter.com/.*/status/.*')
    return validTweetUrl.test(url.trim())
  }

  const handleFaucetSubmit = async () => {
    if (!(receiver && amount && tweetUrl)) {
      showAlert("error", "All fields are required.");
      return
    }
    if (!validateTweetUrl(tweetUrl)) {
      showAlert('error', 'This doesn\'t look like a valid Tweet URL!')
      return
    }

    setLoading(true);
    Requests.executeFaucetRequest(receiver, amount, tweetUrl)
      .then((receipt) => {
        setLoading(false);

        console.info(receipt);

        showAlert("success", "Amount Sent Successfully");
      })
      .catch((err) => {
        setLoading(false);
        //TODO convert alert params to one object
        Requests.handleRequestsErrors(
          err,
          setAlertOpen,
          setSeverity,
          setMessage
        );
      });
  };

  const handleCreateTweet = () => {
    if (!(receiver && amount)) {
      showAlert("error", "Please provide an account address and an amount.");
    } else {
      let tweet = 'https://twitter.com/intent/tweet'
      tweet += '?text=' +
        'Getting ' + amount + ' OONE from OONE Chain for my ' + receiver  + ' account!\n' +
        '+%23OONEChain+%23web3+%23development'
      tweet += '&url=https://explorer1.oonechain.com/'
      // tweet += '&url=https://faucet.quicknode.com/ethereum/sepolia'
      window.open(tweet, '_blank', 'noreferrer');
    }
  };
  
  return (
    <>
      {!loading ? (
        <>
          <Alert
            open={alertOpen}
            setOpen={setAlertOpen}
            severity={severity}
            message={message}
          />
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column w-75">
              <BackToHome />
              <div className="h2 mb-5 d-flex ms-4">
                Send {process.env.REACT_APP_Blockchain_Coin_Name} Coins
              </div>
              <div className="ms-5">
                <div className="d-flex">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Account Address :
                  </div>
                  <input
                    type="text"
                    className="form-control mb-3 w-50"
                    value={receiver}
                    placeholder="Enter account address (0x....)"
                    onChange={(e) => setReceiver(e.target.value)}
                  />
                </div>
                <div className="d-flex">
                  <div className="h5 ms-3 w-25 d-flex align-items-center">
                    Amount :
                  </div>
                  <input
                    type="number"
                    className="form-control mb-3 w-50"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    min="0"
                    max="99999"
                    onInput={handleLimit}
                  ></input>
                </div>
                <div className="d-flex flex-column align-items-center mb-3">
                  <button
                    type="submit"
                    onClick={handleCreateTweet}
                    className="btn btn-primary btn-lg"
                    style={{ minWidth: "15%" }}
                  >
                    Create a Tweet
                  </button>
                </div>
                <div className="d-flex">
                    <div className="h5 ms-3 w-25 d-flex align-items-center">
                      Enter the tweet URL:
                    </div>
                    <input
                      type="text"
                      className="form-control mb-3 w-50"
                      placeholder="https://twitter.com/... /status/..."
                      value={tweetUrl}
                      onChange={(e) => setTweetUrl(e.target.value)}
                    ></input>
                  </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center mb-5">
              <button
                type="submit"
                onClick={handleFaucetSubmit}
                className="btn btn-primary btn-lg"
                style={{ minWidth: "15%" }}
              >
                Send
              </button>
            </div>
          </div>
        </>
      ) : (
        <Loading
          message={"Your request is being handled, please wait"}
          type={"spinningBubbles"}
          color={"#000000"}
          height={50}
          width={50}
        />
      )}
    </>
  );
}

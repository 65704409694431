import * as React from "react";

export default function StakingStepUploadDepositData(props) {
  const [jsonValidatorsNumber, setJsonValidatorsNumber] = React.useState(0);

  React.useEffect(() => {
    props.setNextDisabled(true);
    // eslint-disable-next-line
  }, []);

  const handleDepositDataFileChange = (e) => {
    if (e.target.files) {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        // Save Deposit Date
        props.setDepositData(JSON.parse(e.target.result.toString()));

        // Save JSON Validators number
        setJsonValidatorsNumber(JSON.parse(e.target.result).length)

        // Recalculate all Numbers
        setCalculatedProps(JSON.parse(e.target.result).length)      
      };
    }
  };

  const handleIndexChange = (e) => {
    // Recalculate all Numbers
    setCalculatedProps(jsonValidatorsNumber)
  }

  const setCalculatedProps = (myJsonValidatorsNumber) => {
    const startIndex = Number(document.getElementById('start-index').value);
    let endIndex = Number(document.getElementById('end-index').value);  // Check validatorsEndIndex

    if (endIndex <= 0 || endIndex > myJsonValidatorsNumber -1)
      endIndex = myJsonValidatorsNumber -1
    let calculatedValidatorsNumber = endIndex - startIndex + 1
    if (calculatedValidatorsNumber < 0)
      calculatedValidatorsNumber = 0

    if (calculatedValidatorsNumber > 0) {
      props.setValidatorsNumber(calculatedValidatorsNumber);
      props.setValidatorsStartIndex(startIndex);
      props.setValidatorsEndIndex(endIndex);
      props.setDepositAmount(
        parseInt(process.env.REACT_APP_Deposit_Per_Validator) * calculatedValidatorsNumber
      );
      props.setNextDisabled(false);
    } 
    else
    {
      props.setNextDisabled(true);
    }
    console.log(myJsonValidatorsNumber, startIndex, endIndex, calculatedValidatorsNumber)
 }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="h3 mb-4">Upload your deposit data json</div>
        <div className="ms-4 mb-5">
          <div className="mb-5">
          <div>
              Start Index :
            </div>
            <input
              id="start-index"
              type="text"
              className="form-control mb-3 w-50"
              onChange={handleIndexChange}
            />
            <div>
              to Index :
            </div>
            <input
              id="end-index"
              type="text"
              className="form-control mb-3 w-50"
              onChange={handleIndexChange}
            />
            <br />
            <div className="h5 mb-4">
              Please upload your deposit data json file that was created by the
              tool
            </div>
            <input
              className="ms-3"
              type="file"
              onChange={handleDepositDataFileChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";

export const uploadNFT = async (formData) => {
  const res = await axios({
    method: "post",
    url: `${process.env.REACT_APP_Backend_Url}/nft/upload`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const executeFaucetRequest = (address, amount, tweetUrl) => {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_Backend_Url}/account/request/${Number(amount)}/to/${address}/tweet/${encodeURIComponent(tweetUrl)}`,
  }).then((res) => {
    return res.data;
  });
};

export const handleRequestsErrors = (
  err,
  setAlertOpen,
  setSeverity,
  setMessage
) => {
  let errorMsg = err.response
    ? err.response.data.replace("Error: ", "")
    : err.message;
  setSeverity("error");
  setMessage(errorMsg);
  setAlertOpen(true);
};

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import StakingStepGenerateKeys from "./StakingStepGenerateKeys";
import StakingStepUploadDepositData from "./StakingStepUploadDepositData";
import StakingStepProvideAccount from "./StakingStepProvideAccount";
import BackToHome from "../BackToHome";
import StakingStepConfirmDeposit from "./StakingStepConfirmDeposit";

import * as Helpers from "../../Helpers/Helpers";

import Alert from "../Alert";
import Loading from "../Loading";
import * as Constants from "../../Helpers/Constants";
import StakingStepNextSteps from "./StakingStepNextSteps";

export default function StakingComponent() {
  // Alert component state
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [nextDisabled, setNextDisabled] = React.useState(false);

  // Account Data
  const [depositData, setDepositData] = React.useState("");
  const [publicKey, setPublicKey] = React.useState("");
  const [validatorsNumber, setValidatorsNumber] = React.useState(0);
  const [validatorsStartIndex, setValidatorsStartIndex] = React.useState(0);
  const [validatorsEndIndex, setValidatorsEndIndex] = React.useState(0);
  const [depositAmount, setDepositAmount] = React.useState(0);
  const [accountsLoaded, setAccountsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (window.ethereum?.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(() => {
          setAccountsLoaded(true);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const handleNext = async () => {
    if (activeStep === Constants.Steps.ConfirmDeposit.id)
      await Helpers.makeValidatorsDeposit(publicKey, depositData, validatorsStartIndex, validatorsEndIndex);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStepContent = (step) => {
    let stepDiv;
    switch (Number(step)) {
      case 0:
        stepDiv = <StakingStepGenerateKeys setNextDisabled={setNextDisabled} />;
        break;
      case 1:
        stepDiv = (
          <StakingStepUploadDepositData
            setDepositData={setDepositData}
            setValidatorsNumber={setValidatorsNumber}
            setValidatorsStartIndex = {setValidatorsStartIndex}
            setValidatorsEndIndex = {setValidatorsEndIndex}
            setDepositAmount={setDepositAmount}
            setNextDisabled={setNextDisabled}
          />
        );
        break;
      case 2:
        stepDiv = (
          <StakingStepProvideAccount
            depositData={depositData}
            setDepositData={setDepositData}
            validatorsNumber={validatorsNumber}
            validatorsStartIndex={validatorsStartIndex}
            validatorsEndIndex={validatorsEndIndex}
            depositAmount={depositAmount}
            publicKey={publicKey}
            setPublicKey={setPublicKey}
            setNextDisabled={setNextDisabled}
          />
        );
        break;
      case 3:
        stepDiv = (
          <StakingStepConfirmDeposit
            publicKey={publicKey}
            depositAmount={depositAmount}
            depositData={depositData}
            setNextDisabled={setNextDisabled}
            setAlertOpen={setAlertOpen}
            setSeverity={setSeverity}
            setMessage={setMessage}
            loading={loading}
            setLoading={setLoading}
            handleNext={handleNext}
          />
        );
        break;
      case 4:
        stepDiv = <StakingStepNextSteps setNextDisabled={setNextDisabled} />;
        break;
      default:
        stepDiv = <div>Unexpected Step</div>;
    }
    return <div key="stepContent">{stepDiv}</div>;
  };

  const renderStepButtons = (step, stepsLength) => {
    let buttonsDiv;
    if (step === stepsLength) {
      buttonsDiv = (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={handleReset}>Reset</Button>
        </Box>
      );
    } else {
      buttonsDiv = (
        <Box className="d-flex justify-content-around">
          <button
            disabled={step === 0}
            onClick={handleBack}
            className="btn btn-warning btn-lg"
          >
            Back
          </button>
          <button
            className="btn btn-primary lg"
            onClick={handleNext}
            disabled={nextDisabled}
          >
            Next
          </button>
        </Box>
      );
    }
    return <div key="stepButtons">{buttonsDiv}</div>;
  };

  const renderStep = (step, stepsLength) => {
    return [renderStepContent(step), renderStepButtons(step, stepsLength)];
  };

  return (
    <>
      {!accountsLoaded ? (
        <>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column w-75">
              <BackToHome />

              <div
                className="h5 mb-3 mt-5 text-center alert alert-warning"
                role="alert"
              >
                This service needs metamask extension to work properly,
                <br />
                Please make sure that metamask extension is installed and
                <br />
                at least 1 account is connected to our site to continue
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!loading ? (
            <>
              <Alert
                open={alertOpen}
                setOpen={setAlertOpen}
                severity={severity}
                message={message}
              />
              <div className="d-flex justify-content-center">
                <Box sx={{ width: "75%" }}>
                  <BackToHome />
                  <Stepper activeStep={activeStep} className="mb-5">
                    {Constants.StepsLabels.map((label) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {renderStep(activeStep, Constants.StepsLabels.length)}
                </Box>
              </div>
            </>
          ) : (
            <Loading
              message={"Your request is being handled, please wait"}
              type={"spinningBubbles"}
              color={"#000000"}
              height={50}
              width={50}
            />
          )}
        </>
      )}
    </>
  );
}

import * as React from "react";
import Routing from "./Routing/Routing";
import { BrowserRouter } from "react-router-dom";

function App() {
	return (
		  <React.StrictMode>
			<BrowserRouter
			  style={{ position: "relative" }}
			>
			  <Routing />
			</BrowserRouter>
		  </React.StrictMode>
	  );
}

export default App;

import * as React from "react";
import StakingComponent from "./StakingComponent";

export default function StakingMain() {
  return (
    <div className="d-flex flex-column">
      <h1 className="text-center mb-5">
        {process.env.REACT_APP_Blockchain_Network_Name} Staking services
      </h1>
      <StakingComponent />
    </div>
  );
}

import * as React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../Helpers/Constants";

export default function BackToHome() {
  return (
    <>
      <div className="d-flex mb-5">
        <Link className="btn btn-danger" to={Constants.Paths.Home}>
          ⇐ Back To Home
        </Link>
      </div>
    </>
  );
}

import * as React from "react";
import ImageUploading from "react-images-uploading";

import * as Requests from "../../Helpers/Requests";
import * as Helpers from "../../Helpers/Helpers";
import Alert from "../Alert";
import BackToHome from "../BackToHome";
import Loading from "../Loading";

export default function NFTMint() {
  // Alert component state
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [receiver, setReceiver] = React.useState("");
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [accounts, setAccounts] = React.useState([]);
  const [isCorrectNetwork, setIsCorrectNetwork] = React.useState(false);
  const [uploadDisabled, setUploadDisabled] = React.useState(true);

  const handleImagesChange = (imageList) => setImages(imageList);
  const handleReceiverChange = (e) => setReceiver(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);

  const FormFields = {
    Name: "name",
    Description: "description",
    Image: "image",
    ContractAddress: "contract_address",
    ReceiverAddress: "receiver_address",
  };

  const handleMintNFT = async () => {
    if (
      !(name && description && images[0] && receiver && accounts[0])
    ) {
      setAlertOpen(true);
      setSeverity("error");
      setMessage("All fields are required.");
    } else {
      setLoading(true);
      let formData = new FormData();
      formData.append(FormFields.Name, name);
      formData.append(FormFields.Description, description);
      formData.append(FormFields.Image, images[0].file);

      Requests.uploadNFT(formData)
        .then(async (nftUrl) => {
          let operationDetails = {
            metadataUrl: nftUrl,
            contractAddress: process.env.REACT_APP_NFT_Contract_Address,
            receiverAddress: receiver,
            fundingAddress: accounts[0],
          };

          let operationRes = await Helpers.mintNFT(operationDetails);
          if (operationRes.success) {
            setLoading(false);

            console.info(operationRes.success);

            setSeverity("success");
            setMessage(
              `NFT Minted Successfully with ID ${operationRes.success.events.Transfer.returnValues.tokenId}`
            );
            setAlertOpen(true);
          } else {
            setLoading(false);
            console.error(operationRes.error);
            Requests.handleRequestsErrors(
              operationRes.error,
              setAlertOpen,
              setSeverity,
              setMessage
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          Requests.handleRequestsErrors(
            err,
            setAlertOpen,
            setSeverity,
            setMessage
          );
        });
    }
  };

  React.useEffect(() => {
    setUploadDisabled(!isCorrectNetwork);
  }, [isCorrectNetwork]);

  React.useEffect(() => {
    // Check selected account
    if (window.ethereum?.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((_accounts) => {
          setAccounts(_accounts);
        })
        .catch((err) => console.error(err));

      // Check network id
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((_chainId) => {
          setIsCorrectNetwork(Helpers.checkIsCorrectNetwork(_chainId));
        })
        .catch((err) => console.error(err));
    }
  }, []);

  React.useEffect(() => {
    if (window.ethereum?.isMetaMask) {
      // Tracking Chain id change
      window.ethereum.on("chainChanged", (_chainId) => {
        setIsCorrectNetwork(Helpers.checkIsCorrectNetwork(_chainId));
      });

      // Tracking Account change
      window.ethereum.on("accountsChanged", async () => {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((_accounts) => {
            setAccounts(_accounts);
          })
          .catch((err) => console.error(err));
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!accounts[0] ? (
        <>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-column w-75">
              <BackToHome />

              <div
                className="h5 mb-3 mt-5 text-center alert alert-warning"
                role="alert"
              >
                This service needs metamask extension to work properly,
                <br />
                Please make sure that metamask extension is installed and
                <br />
                at least 1 account is connected to our site to continue
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {!loading ? (
            <>
              <Alert
                open={alertOpen}
                setOpen={setAlertOpen}
                severity={severity}
                message={message}
              />
              <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column w-75">
                  <BackToHome />
                  <div className="h2 mb-5 d-flex ms-4">Create An NFT</div>
                  <div className="ms-5">
                    <div className="h4 mb-4">
                      Please select the correct network and a fees paying
                      account from metamask extension
                    </div>
                    <div className="d-flex">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        NFT Name :
                      </div>
                      <input
                        type="text"
                        className="form-control mb-3 w-50"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        NFT Description :
                      </div>
                      <input
                        type="text"
                        className="form-control mb-3 w-50"
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                    </div>
                    <div className="d-flex">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        Image Upload :
                      </div>
                      <ImageUploading
                        multiple
                        value={images}
                        onChange={handleImagesChange}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper w-50 d-flex flex-column align-items-center mb-3">
                            {images.length < 1 && (
                              <button
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                className="btn btn-success mb-3"
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Upload Image
                              </button>
                            )}
                            {images.length >= 1 && (
                              <button
                                className="btn btn-danger mb-3"
                                onClick={onImageRemoveAll}
                              >
                                Remove Image
                              </button>
                            )}
                            {imageList.map((image, index) => (
                              <div
                                key={index}
                                className="image-item d-flex flex-column align-items-center w-50"
                              >
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  className="mb-3"
                                  style={{ height: "20vh" }}
                                />
                                <div className="image-item__btn-wrapper d-flex justify-content-evenly w-100">
                                  <button
                                    className="btn btn-warning btn-sm"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        Receiver Address :
                      </div>
                      <input
                        type="text"
                        className="form-control mb-3 w-50"
                        value={receiver}
                        onChange={handleReceiverChange}
                      />
                    </div>
                    <div className="d-flex mb-4">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        Fees paying Account :
                      </div>
                      <div className="h5 w-50 d-flex align-items-center">
                        {accounts[0]}
                      </div>
                    </div>

                    <div className="d-flex mb-4">
                      <div className="h5 ms-3 w-25 d-flex align-items-center">
                        Selected network :
                      </div>
                      {isCorrectNetwork ? (
                        <div className="h5 w-50 d-flex align-items-center text-success">
                          You are on{" "}
                          {process.env.REACT_APP_Blockchain_Network_Name}{" "}
                          network
                        </div>
                      ) : (
                        <div className="h5 ms-3 d-flex align-items-center text-danger">
                          You are on different network, Please switch to{" "}
                          {process.env.REACT_APP_Blockchain_Network_Name}{" "}
                          network to proceed
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center mb-5">
                  <button
                    type="submit"
                    onClick={handleMintNFT}
                    className="btn btn-primary btn-lg"
                    disabled={uploadDisabled}
                  >
                    Upload The NFT
                  </button>
                </div>
              </div>
            </>
          ) : (
            <Loading
              message={"Your request is being handled, please wait"}
              type={"spinningBubbles"}
              color={"#000000"}
              height={50}
              width={50}
            />
          )}
        </>
      )}
    </>
  );
}
